import {fonts, colors, zIndex, fontFallback, tokens} from '@commercial-helios/ui/theme'
import {createGlobalStyle} from 'styled-components'

import 'modern-css-reset'
import './font-face.css'

import calendarIcon from '@helios/shared/images/icons/calendar.svg'
import closeIcon from '@helios/shared/images/icons/close-black.svg'

// we need to import these css files to make css tokens available
import '@pleo-io/telescope-tokens/dist/color-scheme-light.css'
import '@pleo-io/telescope-tokens/dist/color.css'

const GlobalStyles = createGlobalStyle`
    html,
    body {
        font-family: ${fontFallback};
        font-weight: ${fonts.weights.normal};
        color: ${colors.text.default};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    /* Overwrites smooth scroll from modern-css-reset */
    html:focus-within {
        scroll-behavior: auto;
    }

    /* Needs to be on body only to allow the header to be sticky */
    body {
        overflow-x: hidden;
    }

    html.wf-active,
    .wf-active body {
        font-family: ${fonts.families.primary};
    }

    /* Matches the primary font while it is loading and the fallback is visible */
    [data-font='primary'] {
        font-family: ${fontFallback};
        letter-spacing: -0.65px;
        word-spacing: -0.15px;
    }

    /* wf-active: Class added to the html by the fontLoader */
    .wf-active [data-font='primary'] {
        font-family: unset;
        letter-spacing: unset;
        word-spacing: unset;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    strong {
        font-weight: ${fonts.weights.semiBold};
    }

    hr {
        width: 100%;
        margin: 0;
        border-color: ${colors.border.default};
        border-style: solid none none;
        border-width: 1px;
    }

    #hubspot-messages-iframe-container {
        z-index: ${zIndex.chatbot} !important;
    }

    &:focus {
        outline: none;
    }

    fieldset {
        width: 100%;
        padding: 0;
        margin: 0;
        border: none;
    }

    legend {
        padding: 0;
    }

    /**
 * We display Google's recommended text on the form where this is used instead of showing the badge.
 * We are allowed to hide the badge as long as we include the reCAPTCHA branding visibly in the user flow.
 * @see https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
 */
    .grecaptcha-badge {
        visibility: hidden;
    }

    @media screen and (prefers-reduced-motion: reduce), (update: slow) {
        * {
            transition-duration: 0.001ms !important;
            animation-duration: 0.001ms !important;
            animation-iteration-count: 1 !important;
            transition-delay: 0.001ms !important;
        }
    }

    &::selection {
        background: ${tokens.pink400};
    }

    input {
        margin: 0;
    }

    input[type='date'] {
        position: relative;
        display: flex;
        justify-content: space-between;

        &::after {
            width: 20px;
            height: 20px;
            pointer-events: none;
            content: '';
            background: url(${calendarIcon}) no-repeat;
            background-position: center;
            background-size: auto 100%;
        }
    }

    input[type='date']::-webkit-calendar-picker-indicator {
        /** if we use display: none, the date-picker doesn't show up */
        position: absolute;
        inset: 0;
        width: auto;
        height: auto;
        color: transparent;
        background: transparent;
    }

    input[type='search']::-webkit-search-cancel-button {
        -webkit-appearance: none;
        height: 1em;
        width: 1em;
        border-radius: 50em;
        background-image: url(${closeIcon});
        background-size: contain;
        opacity: 0;
        pointer-events: none;
    }

    input[type='search']:focus::-webkit-search-cancel-button {
        opacity: 1;
        pointer-events: all;
    }

    @media print {
        * {
            -webkit-print-color-adjust: exact;
            color-adjust: exact;
        }

        #hubspot-messages-iframe-container {
            display: none !important;
            visibility: hidden !important;
        }
    }

    .gatsby-image-wrapper [data-main-image] {
        transition: opacity ${tokens.smoothIn} !important;
    }

    video::-internal-media-controls-overlay-cast-button {
        display: none;
    }
`

export default GlobalStyles
