import {VisuallyHidden} from '@commercial-helios/ui/src/components/visually-hidden'
import {colors, radii, zIndex, tokens} from '@commercial-helios/ui/theme'
import {Root, Portal, Overlay, Content, Close, Title} from '@radix-ui/react-dialog'
import type {ReactNode} from 'react'
import React from 'react'
import styled, {css} from 'styled-components'

import Image from '@/components/image'
import {Text, text} from '@helios/shared/components/text'
import {Close as CloseImage} from '@helios/shared/images/icons/close'
import mq from '@helios/shared/utils/media-query'
import {useGlobalStrings} from '@helios/website/src/providers/global-strings'
import {gutters} from '@helios/website/src/styles/grid'
import type {ImageSource} from '@helios/website/src/types/image'
import type {BackgroundColor} from '@helios/website/src/utils/is-valid-background-color'

export interface Props {
    isOpen?: boolean
    onDismiss?: () => void
    withCloseButton?: boolean
    title?: string
    subtitle?: string | ReactNode
    image?: ImageSource
    children: ReactNode
    dataTestId?: string
    overlayBgColor?: BackgroundColor
    className?: string
    naked?: boolean
}

export const Modal = ({
    children,
    isOpen,
    onDismiss,
    withCloseButton = true,
    title,
    subtitle,
    image,
    dataTestId,
    overlayBgColor,
    className,
    naked,
}: Props) => {
    return (
        <Root
            open={isOpen}
            onOpenChange={(open) => {
                if (open || !onDismiss) {
                    return
                }
                onDismiss()
            }}
            data-testid={dataTestId}
        >
            <Portal>
                <DialogOverlay className={className} $overlayBgColor={overlayBgColor}>
                    {naked ? (
                        children
                    ) : (
                        <DialogContent>
                            {withCloseButton && <ModalCloseButton dataTestId={dataTestId} />}

                            {!!title && (
                                <ModalImageAndTitles
                                    title={<DialogTitle>{title}</DialogTitle>}
                                    subtitle={subtitle}
                                    image={image}
                                />
                            )}

                            <DialogBody>{children}</DialogBody>
                        </DialogContent>
                    )}
                </DialogOverlay>
            </Portal>
        </Root>
    )
}

export const ModalCloseButton = ({
    dataTestId,
    size = '20px',
    className,
    ...props
}: {
    dataTestId?: string
    size?: string
    className?: string
    onClick?: () => void
}) => {
    const globalStrings = useGlobalStrings()

    return (
        <CloseWrapper className={className}>
            <CloseButton
                $size={size}
                {...(dataTestId && {
                    'data-testid': `${dataTestId}-close-button`,
                })}
                {...props}
            >
                <VisuallyHidden>{globalStrings.close_button_text}</VisuallyHidden>
                <CloseIcon />
            </CloseButton>
        </CloseWrapper>
    )
}

type ModalImageAndTitlesProps = {
    title?: string | ReactNode
    subtitle?: string | ReactNode
    image?: ImageSource
}

export const ModalImageAndTitles = ({image, title, subtitle}: ModalImageAndTitlesProps) => (
    <IllustrationAndTitles>
        {image && <Illustration image={image} width={80} aria-hidden />}

        <div>
            {typeof title === 'string' ? (
                <Text as="h2" $variant="heading-base" $center>
                    {title}
                </Text>
            ) : (
                title
            )}

            {typeof subtitle === 'string' ? (
                <SubtitleText $variant="paragraph-base" $color="muted" $center>
                    {subtitle}
                </SubtitleText>
            ) : (
                subtitle
            )}
        </div>
    </IllustrationAndTitles>
)

type ModalContentProps = {
    children: ReactNode
}

export const ModalContent = ({children}: ModalContentProps) => (
    <ContentWrapper>{children}</ContentWrapper>
)

type ModalActionsProps = {
    children: ReactNode
}

export const ModalActions = ({children}: ModalActionsProps) => (
    <ActionsWrapper>{children}</ActionsWrapper>
)

const mobileBreakpoint = 'xs'

const sectionPadding = css`
    padding: ${tokens.spacing20};

    ${mq.min(mobileBreakpoint)} {
        padding-right: ${tokens.spacing40};
        padding-left: ${tokens.spacing40};
    }
`

const CloseWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: ${tokens.spacing10};
    justify-content: flex-end;
`

const CloseButton = styled(Close)<{$size: string}>`
    display: flex;
    width: ${(props) => props.$size};
    aspect-ratio: 1;
    padding: 0;
    cursor: pointer;
    background: none;
    border: none;
    color: inherit;
`

const CloseIcon = styled(CloseImage).attrs({'aria-hidden': true})`
    width: 100%;
    height: 100%;
    object-fit: contain;
    stroke: ${tokens.shade800};
`

const DialogOverlay = styled(Overlay)<{$overlayBgColor?: Props['overlayBgColor']}>`
    ${gutters}

    position: fixed;
    inset: 0;
    z-index: ${zIndex.modalOverlay};
    display: flex;
    padding-top: ${tokens.spacing24};
    padding-bottom: ${tokens.spacing24};
    overflow: auto;
    flex-direction: column;
    align-items: center;
    background: ${(props) =>
        props.$overlayBgColor ? props.$overlayBgColor : tokens.shade900lighten50};
`

export const dialogContentStyles = css`
    width: 100%;
    max-width: 522px;
    margin: auto;
    background: ${colors.background.default};
    border-radius: ${radii.arc8};
    outline: none;
    box-shadow: ${tokens.shadowRaise};
`

const DialogContent = styled(Content)`
    ${dialogContentStyles}
`

const IllustrationAndTitles = styled.div`
    ${sectionPadding}

    display: grid;
    justify-items: center;
    row-gap: ${tokens.spacing8};
`

const DialogTitle = styled(Title)`
    ${text({$variant: 'title-base', $center: true})}
`

const SubtitleText = styled(Text)`
    margin-top: ${tokens.spacing10};
`

const Illustration = styled(Image)`
    width: 60px;
    height: 80px;
    object-fit: contain;

    ${mq.min(mobileBreakpoint)} {
        width: 80px;
    }
`

const DialogBody = styled.div`
    display: grid;
    padding: ${tokens.spacing20};
    row-gap: ${tokens.spacing40};

    ${mq.min(mobileBreakpoint)} {
        padding: ${tokens.spacing40};
    }
`

const ContentWrapper = styled.div`
    display: grid;
    row-gap: ${tokens.spacing20};

    ${mq.min(mobileBreakpoint)} {
        row-gap: ${tokens.spacing24};
    }
`

/**
 * If there is more than one action button then we want to display them side by side
 * If there is only one action button then we want to display it full width
 */
const ActionsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${tokens.spacing20};
    justify-content: center;
    width: 100%;
    margin-top: ${tokens.spacing20};

    & > * {
        flex-basis: 100%;
    }

    ${mq.min(mobileBreakpoint)} {
        & > *:not(:only-child) {
            flex-basis: revert;
        }
    }
`
