import Cookies from 'js-cookie'

import {paramCookiesMap} from '@/config/cookies'
import useParams from '@/hooks/use-params'
import compactObject from '@/utils/compact-object'
import {useLocale} from '@helios/shared/providers/locale'

type Args = {
    redirectPath?: string
    params?: Record<string, string>
}

export const useSignupRedirect = (args?: Args) => {
    const currentLocale = useLocale()
    const existingParams = useParams()
    const redirectPath = args?.redirectPath

    if (redirectPath) {
        return appendParams(redirectPath, new URLSearchParams(args?.params))
    }

    const params = new URLSearchParams(
        compactObject({
            ...Object.fromEntries(existingParams),
            ...args?.params,
            lang: currentLocale.language,
            locale: currentLocale.code,
            pt_ref: 'website',
            utm_source: Cookies.get(paramCookiesMap.utmSource) || '',
            utm_medium: Cookies.get(paramCookiesMap.utmMedium) || '',
            utm_campaign: Cookies.get(paramCookiesMap.utmCampaign) || '',
            utm_term: Cookies.get(paramCookiesMap.utmTerm) || '',
            utm_content: Cookies.get(paramCookiesMap.utmContent) || '',
            latest_utm_source: Cookies.get(paramCookiesMap.latestUtmSource) || '',
            latest_utm_medium: Cookies.get(paramCookiesMap.latestUtmMedium) || '',
            latest_utm_campaign: Cookies.get(paramCookiesMap.latestUtmCampaign) || '',
            latest_utm_term: Cookies.get(paramCookiesMap.latestUtmTerm) || '',
            latest_utm_content: Cookies.get(paramCookiesMap.latestUtmContent) || '',
            s_id: Cookies.get(paramCookiesMap.financeAdsCookieName) || '',
            _fbc: Cookies.get(paramCookiesMap.metaClickId) || '',
            _fbp: Cookies.get(paramCookiesMap.metaBrowserId) || '',
            _ttcid: Cookies.get(paramCookiesMap.tikTokPixelId) || '',
            gclid: Cookies.get(paramCookiesMap.googleClickIdentifier) || '',
        }),
    )

    return `${process.env.GATSBY_PLEO_APP_URL}/register?${params}`
}

const appendParams = (path: string, params: URLSearchParams) => {
    if (Boolean(Array.from(params.keys()).length)) {
        return `${path}?${params}`
    }

    return path
}
