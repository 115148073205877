import {tokens} from '@commercial-helios/ui/theme'
import React from 'react'
import styled from 'styled-components'

import {Text} from '@helios/shared/components/text'
import mq from '@helios/shared/utils/media-query'
import {useGlobalStrings} from '@helios/website/src/providers/global-strings'

const contentId = 'content'

const SkipToContent = () => {
    const globalStrings = useGlobalStrings()

    return (
        <Text
            as={SkipToContentWrapper}
            href={`#${contentId}`}
            $color="inverted"
            $variant="paragraph-large"
        >
            {globalStrings.skipToContent}
        </Text>
    )
}

interface Props {
    children: React.ReactNode
}

export const Main = ({children}: Props) => <main id={contentId}>{children}</main>

const SkipToContentWrapper = styled.a`
    &:not(:focus) {
        position: absolute;
        transform: translateY(-100%);
    }

    &:focus {
        display: block;
        padding: ${tokens.spacing12} ${tokens.spacing24};
        background-color: ${tokens.shade800};

        ${mq.min('sm')} {
            padding-right: ${tokens.spacing40};
            padding-left: ${tokens.spacing40};
        }
    }
`

export default SkipToContent
