import {GatsbyImage} from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'

import {isSvgOrEncodedImage} from '@helios/shared/utils/is-svg-or-encoded-image'
import type {ImageSource} from '@helios/website/src/types/image'

interface Props {
    image: ImageSource
    width?: number
    height?: number
    classname?: string
    objectFit?: 'contain' | 'cover'
    style?: React.CSSProperties
    'data-testid'?: string
}

const Image = ({image, width, height, classname, objectFit, ...props}: Props) => {
    if (!image?.url && !image?.gatsbyImageData) {
        return null
    }

    const {url, alt, dimensions, gatsbyImageData, loading = 'lazy'} = image

    const [canonical, defaultParams] = url.split('?')
    if (!isSvgOrEncodedImage(canonical) && gatsbyImageData) {
        return (
            <GatsbyImage
                image={gatsbyImageData}
                alt={alt || ''}
                objectFit={objectFit}
                loading={loading}
                {...props}
            />
        )
    }

    let imageSrc = canonical

    if (!isSvgOrEncodedImage(canonical)) {
        const params = new URLSearchParams(defaultParams)

        params.append('dpr', '2')

        if (width) {
            params.append('w', width.toString())
        }

        if (height) {
            params.append('h', height.toString())
        }

        imageSrc = `${canonical}?${params.toString()}`
    }

    return (
        <SizedImage
            className={classname}
            src={imageSrc}
            alt={alt || ''}
            width={dimensions.width}
            height={dimensions.height}
            loading={loading}
            {...props}
        />
    )
}

const SizedImage = styled.img`
    height: auto;
`

export default Image
