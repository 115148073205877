import {Stack} from '@commercial-helios/ui/src/components/stack'
import {tokens} from '@commercial-helios/ui/theme'
import React from 'react'
import styled, {css} from 'styled-components'
import {useClipboard} from 'use-clipboard-copy'

import {Text} from '@helios/shared/components/text'
import copyIcon from '@helios/shared/images/icons/copy.svg'
import type {InputStylesProps} from '@helios/website/src/styles/inputs'
import {inputStyles, Label} from '@helios/website/src/styles/inputs'
import {ellipsisStyles} from '@helios/website/src/styles/utils'

interface CommonProps extends Omit<JSX.IntrinsicElements['input'], 'ref' | 'onChange' | 'css'> {
    label?: string
    onChange?: (value: string) => void
    copyable?: boolean
    error?: string | React.ReactNode | null
    required?: boolean
    'data-testid'?: string
}

interface Copyable {
    copyable: true
    copiedText: string
}

interface NotCopyable {
    copyable?: false
}

const isCopyable = (props: Copyable | NotCopyable): props is Copyable => !!props.copyable

export type Props = CommonProps & (Copyable | NotCopyable)

const Input = ({
    label,
    type = 'text',
    placeholder,
    value,
    onChange,
    required,
    error,
    ...props
}: Props) => {
    const clipboard = useClipboard({
        copiedTimeout: 1000,
    })

    const id = React.useId()

    return (
        <Wrapper $space={8} $stretch>
            {label && (
                <Label htmlFor={id} $required={required}>
                    {label}
                </Label>
            )}

            <InputWrapper>
                <StyledInput
                    id={id}
                    type={type}
                    placeholder={placeholder}
                    value={clipboard.copied && isCopyable(props) ? props.copiedText : value}
                    onChange={(e) => onChange?.(e.target.value)}
                    readOnly={props.copyable}
                    copyable={props.copyable}
                    $hasError={!!error}
                    ref={clipboard.target}
                    required={required}
                    {...props}
                />

                {props.copyable && (
                    <IconButton type="button" onClick={clipboard.copy}>
                        <img src={copyIcon} alt="" />
                    </IconButton>
                )}
            </InputWrapper>

            {error && typeof error === 'string' && (
                <Text
                    $variant="label-base"
                    $color="error"
                    {...(props['data-testid'] && {'data-testid': `${props['data-testid']}-error`})}
                >
                    {error}
                </Text>
            )}
        </Wrapper>
    )
}

const InputWrapper = styled.div`
    position: relative;
`

const Wrapper = styled(Stack)`
    position: relative;
    width: 100%;
`

const iconSize = tokens.spacing24
const iconPadding = tokens.spacing12

const IconButton = styled.button`
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding: 0 ${iconPadding};
    cursor: pointer;
    background: none;
    border: none;

    img {
        width: ${iconSize};
        height: ${iconSize};
    }
`

interface InputProps extends InputStylesProps {
    copyable?: boolean
}

const StyledInput = styled.input<InputProps>(({copyable, $hasError}) => {
    const copyableStyles = css`
        padding-right: calc(${iconSize} + (${iconPadding} * 2));
    `

    return css`
        ${inputStyles({$hasError})}

        ${copyable && copyableStyles}

        ${ellipsisStyles}
    `
})

export default Input
